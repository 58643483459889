bovoApp.
  factory('Order', ['$http', function ($http) {
    return {
      dashboard: function (data) {
        return $http.post('api/dashboard', data);
      },
      calendar: function (data) {
        return $http.post('api/calendar', data);
      },
      dashboardOffice: function (data) {
        return $http.post('api/dashboard-office', data);
      },
      workers: function (e) {
        return $http.get('api/dashboard-workers', { params: e, ignoreLoadingBar: true });
      },
      order: function (id) {
        return $http.get('api/order/' + id);
      },
      newOrderData: function () {
        return $http.get('api/new-order');
      },
      newOrder: function (e) {
        return $http.post('api/new-order', e);
      },
      orders: function (data, loading) {
        return $http.post('api/orders', data, { ignoreLoadingBar: !loading });
      },
      notifications: function (data) {
        return $http.post('api/notifications', data);
      },
      orderInvoices: function (data) {
        return $http.post('api/order-invoices', data);
      },
      updateStatusInvoice: function (data) {
        return $http.post('api/order-invoices-status', data);
      },
      sendNote: function (data) {
        return $http.post('api/send-note', data);
      },
      sendSms: function (data) {
        return $http.post('api/send-sms-customer', data);
      },
      sendCallRecord: function (data) {
        return $http.post('api/send-call-record', data);
      },
      refreshOrders: function () {
        return $http.get('api/refresh-orders');
      },
      updateOrder: function (data) {
        return $http.post('api/update-order', data);
      },
      updateOrderField: function (data) {
        return $http.post('api/update-order-fields', data);
      },
      tour: function (data) {
        return $http.post('api/tour', data);
      },
      getAddressFromCoord: function (url) {
        return $http.jsonp(url);
      },
      assignWorker: function (data) {
        return $http.post('api/assign-worker', data);
      },
      loadRoute: function (id) {
        return $http.get('api/get-route/' + id);
      },
      sendComment: function (data) {
        return $http.post('api/send-comment-order', data);
      },
      changeWorkStatus: function (data) {
        return $http.post('api/change-work-status', data);
      },
      changeArrived: function (data) {
        return $http.post('api/change-work-arrived', data);
      },
      changeWorkStatusOffice: function (data) {
        return $http.post('api/change-work-status', data, { ignoreLoadingBar: true });
      },
      deleteWork: function (data) {
        return $http.post('api/delete-work', data);
      },
      deleteTask: function (data) {
        return $http.post('api/delete-task', data);
      },
      donwloadSampleExcel: function () {
        return $http.get('api/download-sample-order-excel');
      },
      remainingTime: function (id, loading) {
        if (typeof loading === 'undefined') {
          return $http.get('api/get-remaining-time/' + id);
        } else {
          return $http.get('api/get-remaining-time/' + id, {
            ignoreLoadingBar: true
          });
        }
      },
      generateTotalOrdersReportInZip: function (data) {
        return $http.post('api/download-total-orders-reports-in-zip', data);
      },
      removeDownloadFile: function (data) {
        return $http.post('api/remove-download-file', data);
      },
      generateExcelReport: function (data) {
        return $http.post('api/download-orders-reports-in-excel', data);
      },
      generateSupportExcelReport: function (data) {
        return $http.post('api/supports/orders/excel', data);
      },
      generateExcelReportNotification: function (data) {
        return $http.post('api/download-notification-reports-in-excel', data);
      },
      deleteOrders: function (data) {
        return $http.post('api/delete-orders', data);
      },
      getWorkersForOptimizing: function () {

        return $http.get('api/get-available-workers-for-optimization', {
          ignoreLoadingBar: true
        });
      },
      routeOptimization: function (data) {
        return $http.post('api/route-optimization', data, { ignoreLoadingBar: false });
      },
      getLatAndLngOfWorkerOrders: function (data) {
        return $http.post('api/get-lat-and-lng-of-worker-orders', data, { ignoreLoadingBar: true });
      },
      updateChecklist: function (data) {
        return $http.post('api/update-checklist', data);
      },
      updatePaymentData: function (data) {
        return $http.post('api/update-payment', data);
      },
      sendOffer: function (data) {
        return $http.post('api/send-offer', data);
      },
      assignOrderToRoute: function (data) {
        return $http.post('api/assign-order-to-route', data);
      },
      exportProof: function (id) {
        return $http.get('api/export-proof/' + id);
      },
      movement: function (data) {
        return $http.post('api/order-movement', data);
      },
      movementInit: function (data) {
        return $http.get('api/order-movement');
      },
      routeOrder: function (data) {
        return $http.post('api/route-order', data);
      },
      addRouteOrder: function (data) {
        return $http.post('api/add-route-order', data);
      },
      routeOrderInit: function (data) {
        return $http.get('api/route-order', data);
      },
      reverseGeo: function (data) {
        return $http.post('api/reverse-geo', data);
      },
      supportNotes: function (data) {
        return $http.post('api/order/notes/support', data);
      },
      infosetNotes: function (data) {
        return $http.post('api/order/notes/infoset', data);
      },
      oldOrder: function (data) {
        return $http.post('api/waiting-orders', data);
      },
      doorPayOrder: function (data) {
        return $http.post('api/door-pay-order', data);
      },
      notesOrder: function (data) {
        return $http.post('api/note-order', data);
      },
      exportOrderMoves(data) {
        return $http.post('api/excel-export-order-move/'+ data);
      },
      exportOrderActivity(data) {
        return $http.post('api/excel-export-order-activity', data);
      },
      getMngCargoLogs: function(id) {
        return $http.get('api/mng-cargo-logs/' + id);
      },
      getSendeoOrder: function(id) {
        return $http.get('api/sendeo-order/' + id);
      },
      getKargomSendeOrder: function(id) {
        return $http.get('api/kargom-sende-order/' + id);
      },
      getMngReturnOrder: function(id) {
        return $http.get('api/mng-return-order/' + id);
      },
      getGknKargoOrder: function(id) {
        return $http.get('api/gkn-kargo-order/' + id);
      },
      getScottyOrder: function(id) {
        return $http.get('api/scotty-order/' + id);
      },
      getYangoOrder: function(id) {
        return $http.get('api/yango-order/' + id);
      },
      getTalhaNakliyatOrder: function(id) {
        return $http.get('api/talha-nakliyat-order/' + id);
      },
      getAnkaraKargoOrder: function(id) {
        return $http.get('api/ankara-kargo-order/' + id);
      },
      getKolayGelsinOrder: function(id) {
        return $http.get('api/kolay-gelsin-order/' + id);
      },
      getDhlOrder: function(id) {
        return $http.get('api/dhl-order/' + id);
      },
      getDhlUkReturnOrder: function(id) {
        return $http.get('api/dhl-uk-return-order/' + id);
      },
      getDhlEuReturnOrder: function(id) {
        return $http.get('api/dhl-eu-return-order/' + id);
      },
      getAmazonShippingOrder: function(id) {
        return $http.get('api/amazon-shipping-order/' + id);
      },
      getKargoTurkOrder: function(id) {
        return $http.get('api/kargoturk-order/' + id);
      },
      getHerodispatcherOrder: function(id) {
        return $http.get('api/hero-dispatcher-order/' + id);
      },
      getBoltOrder: function(id) {
        return $http.get('api/bolt-order/' + id);
      },
      getHepiJetOrder: function(id) {
        return $http.get('api/hepsi-jet-order/' + id);
      },
      getSuratOrder: function(id) {
        return $http.get('api/surat-order/' + id);
      },
      getGelAlOrder: function(id) {
        return $http.get('api/gel-al-order/' + id);
      },
      warnings: function (data) {
        return $http.post('api/order-warnings', data);
      },
      resolveWarning(data) {
        return $http.post('api/order-warning-resolve', data);
      },
      integrationErrors(data) {
        return $http.post('api/order-integration-errors', data);
      },
      pollResults(data) {
        return $http.post('api/order-poll-results', data);
      },
      thirdPartyDecisions: function (data) {
        return $http.post('api/third-party-decisions', data);
      },
      importThirdPartyDecisions: function (data) {
        return $http.post('api/import-third-party-decisions', data);
      },
      updateThirdPartyDecisions: function (data) {
        return $http.post('api/update-third-party-decisions', data);
      },
      saveThirdPartyDecisions: function (data) {
        return $http.post('api/save-third-party-decisions', data);
      },
      deleteDocument: function (data) {
        return $http.post('api/delete-documents', data);
      },
      compensate: function (data) {
        return $http.post('api/compensate', data);
      },
    };
  }]);